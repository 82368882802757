import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as variables from "../utils/variables"
import "../css/typography.css"

class contactPage extends React.Component {
  render() {
    const siteTitle = "Contact"
    
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Contact" />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Livio Meyer Fotografie - Contact</title>
          <link rel="canonical" href="http://livio-meyer-fotografie.ch/contact" />
          <html lang="de" />
        </Helmet>
        <Bio />
        <h2
          style={{
            color: variables.COLOR_blue,
            fontFamily: `FF Bau Bold`,
          }}
        >
          Bitte hinterlassen Sie eine Nachricht.
        </h2>
        <form
          name="contact"
          method="POST"
          action="/success"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <Container>
            <p
              style={{paddingRight: `5px`}}
            >
              <label htmlFor="name">Ihr Name: <Input type="text" name="name" id="name"/></label>   
            </p>
            <p
              style={{paddingLeft: `5px`}}
            >
              <label htmlFor="email">Ihre Emailadresse: <Input type="email" name="email" id="email"/></label>
            </p>
          </Container>
          <p>
            <label htmlFor="message">Ihre Nachricht: <TextArea name="message" id="message"></TextArea></label>
          </p>
          <p>
            <button type="submit" aria-label="Submit Message">Abschicken!</button>
          </p>
        </form>
      </Layout>
    )
  }
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Input = styled.input`
  width: 100%;
  display: flex;
  border-radius: 4px;
  border-color: black;
  border-width: thin;
`

const TextArea = styled.textarea`
  width: 100%;
  display: flex;
  border-radius: 4px;
  border-color: black;
  border-width: thin;
`

export default contactPage